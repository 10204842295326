import React from "react";

function logo() {
  return (
    <svg
      // className='logo animated pulse delay-1s'
      viewBox='0 0 550 97'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M48.3544 20.7656H29.9619V76.8329H46.5744V46.871L65.5602 76.8329H83.0627V20.7656H66.4501V50.1342L48.3544 20.7656Z'
        fill='#342E30'
      />
      <path
        d='M107.388 22.5455H90.7756V34.4116H107.388V22.5455Z'
        fill='#342E30'
      />
      <path
        d='M91.3689 76.833H107.685C107.388 76.5364 107.388 75.9431 107.388 71.7899V37.6749H90.7756V71.7899C90.7756 76.2397 91.0723 76.833 91.3689 76.833Z'
        fill='#342E30'
      />
      <path
        d='M114.508 37.6748V76.8329H130.824V55.4739C130.824 51.9141 133.79 49.5409 137.647 49.5409C141.503 49.5409 143.283 51.6174 143.283 55.4739C143.283 76.2396 143.283 76.8329 143.876 76.8329H159.896C159.599 76.5362 159.599 74.7563 159.599 51.9141C159.599 42.7179 155.149 36.4882 144.47 36.4882C138.24 36.4882 132.604 38.5647 130.23 43.0145V37.3781H114.508V37.6748Z'
        fill='#342E30'
      />
      <path
        d='M166.422 37.6748V76.8329H182.738V55.4739C182.738 51.9141 185.704 49.5409 189.561 49.5409C193.417 49.5409 195.197 51.6174 195.197 55.4739C195.197 76.2396 195.197 76.8329 195.791 76.8329H211.81C211.513 76.5362 211.513 74.7563 211.513 51.9141C211.513 42.7179 207.063 36.4882 196.384 36.4882C190.154 36.4882 184.518 38.5647 182.145 43.0145V37.3781H166.422V37.6748Z'
        fill='#342E30'
      />
      <path
        d='M309.112 33.5217C314.748 33.5217 317.418 35.005 318.901 37.6749C319.791 39.4548 320.385 41.5314 320.385 43.6079H337.887C337.887 41.2347 337.59 37.3782 336.404 34.115C334.031 26.1054 326.318 19.8757 309.112 19.8757C297.839 19.8757 290.126 22.5456 285.083 28.182C280.93 32.9284 278.557 39.7514 278.557 48.9477C278.557 59.0338 280.93 66.1535 285.38 70.8999C290.423 75.6464 297.839 78.0196 308.518 78.0196C323.648 78.0196 331.657 73.5698 335.514 65.2635C337.294 62.0004 337.59 57.5506 337.887 55.1774H320.385C320.088 57.5506 319.791 59.3305 318.308 61.1104C316.825 63.187 314.155 64.6702 308.815 64.6702C304.959 64.6702 301.399 63.7803 299.322 61.1104C297.542 58.7372 296.652 55.1774 296.652 49.2443C296.652 44.2012 297.246 40.0481 299.026 37.6749C301.399 34.7083 304.662 33.5217 309.112 33.5217Z'
        fill='#342E30'
      />
      <path
        d='M248.001 37.6749C248.001 38.2682 239.102 61.1105 239.102 61.7038C239.102 61.1105 230.202 37.9716 229.906 37.6749H212.7L230.202 77.1297L221.896 95.8188H239.102L264.614 37.6749H248.001V37.6749Z'
        fill='#342E30'
      />
      <path
        d='M366.366 77.7229C376.452 77.7229 383.868 74.4598 387.725 69.12C390.098 66.1535 391.284 62.0004 391.284 57.2539C391.284 52.5075 390.098 48.3544 387.725 45.0912C383.868 40.3447 377.045 36.7849 366.366 36.7849C355.093 36.7849 348.863 40.3447 345.007 45.3878C342.337 48.3544 341.15 52.8041 341.15 57.2539C341.15 62.297 342.633 66.1535 344.71 69.12C348.863 74.1631 355.093 77.7229 366.366 77.7229ZM359.246 51.6175C360.433 49.8376 362.806 48.651 366.662 48.651C370.519 48.651 372.595 49.8376 374.079 51.3209C375.265 52.8041 375.562 55.1774 375.562 57.2539C375.562 59.6271 374.969 61.7037 374.079 63.187C372.595 64.9669 370.222 66.1535 366.662 66.1535C363.102 66.1535 360.433 64.9669 359.246 63.187C358.356 61.7037 357.763 59.6271 357.763 57.2539C357.763 55.1774 358.059 53.1008 359.246 51.6175Z'
        fill='#342E30'
      />
      <path
        d='M427.773 20.1724V41.5314C425.399 38.5648 420.356 36.7849 414.72 36.7849C407.6 36.7849 401.371 39.7514 398.107 44.4979C395.734 47.7611 394.251 52.2108 394.251 57.2539C394.251 62.5937 395.438 66.7468 398.107 70.01C401.371 74.7564 407.007 77.1296 413.83 77.1296C421.246 77.1296 426.289 74.4598 428.069 71.4932L429.256 76.5363H444.089C444.089 76.5363 443.495 72.9765 443.495 70.6033C443.495 59.3305 443.495 19.8757 443.495 19.8757H427.773V20.1724ZM426.289 63.4836C424.806 65.2635 422.73 66.1535 419.466 66.1535C415.907 66.1535 413.533 64.6702 412.347 63.187C411.457 61.7037 410.863 59.6271 410.863 57.5506C410.863 55.474 411.457 53.1008 412.643 51.6175C414.127 50.1343 416.203 48.9477 419.763 48.9477C423.026 48.9477 425.103 50.1343 426.289 51.3209C427.476 52.8041 428.366 55.1774 428.366 57.5506C428.069 59.6271 427.476 62.0004 426.289 63.4836Z'
        fill='#342E30'
      />
      <path
        d='M496.003 49.2443C492.74 41.828 485.62 36.7849 473.754 36.7849C463.964 36.7849 457.141 39.7514 452.988 45.0912C450.318 48.3544 448.835 52.5075 448.835 57.2539C448.835 62.5937 450.318 66.4501 452.692 69.7133C456.845 75.3497 463.964 77.7229 473.754 77.7229C483.247 77.7229 489.773 75.3497 493.926 71.1966C496.003 68.8234 497.189 66.1535 497.783 63.7803H482.06C482.06 64.3736 481.17 65.2635 479.984 66.1535C478.797 67.0434 476.72 67.6368 473.754 67.6368C470.194 67.6368 467.524 66.7468 466.337 65.2635C465.448 64.0769 465.151 62.8903 464.854 61.7037H497.783C498.079 56.9573 497.486 52.8041 496.003 49.2443ZM464.558 53.3974C464.558 52.5075 465.151 51.0242 466.041 50.1343C467.524 48.3544 469.897 47.4644 473.457 47.4644C476.72 47.4644 479.39 48.651 480.577 50.1343C481.467 51.3209 481.763 52.5075 481.763 53.3974H464.558Z'
        fill='#342E30'
      />
      <path
        d='M519.735 61.1104H503.122V77.1296H519.735V61.1104Z'
        fill='#342E30'
      />
      <path
        d='M501.342 20.7656L503.716 57.8472H519.142L521.515 20.7656H501.342Z'
        fill='#342E30'
      />
      <path
        d='M528.338 0H21.6556C9.78953 0 0 9.78953 0 21.6556V74.4597C0 86.3258 9.78953 96.1154 21.6556 96.1154H212.403L216.26 87.2158H21.6556C14.536 87.2158 8.89957 81.5794 8.89957 74.4597V21.6556C8.89957 14.536 14.536 8.89957 21.6556 8.89957H528.338C535.457 8.89957 541.094 14.536 541.094 21.6556V74.4597C541.094 81.5794 535.457 87.2158 528.338 87.2158H252.451L248.595 96.1154H528.338C540.204 96.1154 549.993 86.3258 549.993 74.4597V21.6556C550.29 9.78953 540.501 0 528.338 0Z'
        fill='#342E30'
      />
    </svg>
  );
}

export default logo;
